@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg: #105173;
  --dark: #051720;
  --bg-dark-2: #061f2c;
  --bg-dark-3: #0b364e;
  --bg-primary: #1e96c7;
  --bg-secondary: #105173;
  --bg-accent: #135c79;
  --text-primary: #fff;
  --primary: #1e96c7;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@400;500;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/digital-7-mono");

@font-face {
  font-family: "Helvarabic";
  src: url(Helvarabic.ttf);
  ascent-override: 100%;
}

.fill-gradient {
  fill: url(#linear-gradient);
}

.bg-image {
  position: relative;
  z-index: 0;
}

.bg-image::before {
  background-image: url("../public/pattern.svg");
  z-index: 0;
  background-size: 90%;
  height:100%;
  opacity: 0.1;
  background-position: center;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  position: relative;
  font-family: "Vazirmatn", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  z-index: 10;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.card {
  transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border-radius: 0.5em;
  filter: drop-shadow(0 0 0.5em var(--bg-dark-2));
}

.card:hover {
  transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
}

#categories::-webkit-scrollbar {
  width: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-arabic {
  font-family: "Helvarabic", sans-serif;
}

.font-number {
  font-weight: 600;
}
.font-digital {
  font-family: "Digital-7", sans-serif;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--bg-secondary);
  cursor: pointer;
  border-radius: 50%;
}

.navbar-collapse {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s ease-out;
}

.navbar-collapse.open {
  max-height: 300px;
}

.navbar-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
}

.gradient {
  background: linear-gradient(
    to right,
    var(--gradient-color) 0%,
    transparent 100%
  );
}

.wmde-markdown > * {
  font-weight: 400 !important;
}
.wmde-markdown blockquote {
  border-right: 4px solid var(--bg-secondary);
  border-left: none;
  padding: 1em;
  margin: 1em 0;
  color: var(--text-primary);
}

.wmde-markdown table tr {
  background: transparent;
  color: white;
}

.wmde-markdown hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border: 0;
  height: 1.75px;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: var(--bg);
}

.wmde-markdown h2 {
  border-bottom: 2px solid var(--bg);
}

.wmde-markdown h1 {
  border-bottom: 2px solid var(--bg);
}

.wmde-markdown p > img {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 0.5rem;
  width: 60%;
}
/* selector for p's that contain images */
.wmde-markdown p:has(> img) {
  text-align: center;
}

.wmde-markdown a {
  color: var(--bg-accent);
  text-underline-offset: 6px;
  text-decoration: underline;
  /* underline width */
  text-decoration-thickness: 1px;
}

.image {
  transform: perspective(1500px) rotateX(12deg);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: transform 1s ease 0s;
}

.image:hover {
  transform: perspective(3000px) rotateX(5deg);
}

.gradient-transparent {
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    var(--bg-dark-2) 20%,
    var(--bg-dark-2) 80%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent 0%,
    var(--bg-dark-2) 20%,
    var(--bg-dark-2) 80%,
    transparent 100%
  );
}

.rfm-initial-child-container {
  gap: 2rem !important;
}